import axios from 'axios';

export default {
    getInviteByCode(inviteCode) {
        return axios.get(window.API_BASE + '/invites/' + inviteCode);
    },

    acceptInvite(inviteCode, params) {
        return axios.post(window.API_BASE + '/invites/' + inviteCode + '/accept', params);
    }
}