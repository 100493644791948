<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <div class="logo-wrapper">
        <img height="30" src="@/assets/images/logo.svg" alt="" />
      </div>

      <base-card>
        <v-progress-linear
          :active="isLoading"
          :indeterminate="isLoading"
          absolute
          top
          color="primary"
        ></v-progress-linear>
        <v-card-text v-if="!isLoading" class="text-center">
          <v-form ref="form" @submit.prevent="formSubmit">
            <v-text-field
              type="name"
              name="name"
              label="Name"
              :counter="10"
              :rules="nameRules"
              :disabled="isSaving"
              v-model="name"
            ></v-text-field>

            <v-text-field
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              label="Password"
              :counter="10"
              :rules="passwordRules"
              :disabled="isSaving"
              v-model="password"
              @click:append="show = !show"
            ></v-text-field>

            <v-text-field
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              label="Password Confirmation"
              :counter="10"
              :rules="passwordConfirmationRules"
              :disabled="isSaving"
              v-model="passwordConfirmation"
              @click:append="show = !show"
            ></v-text-field>

            <v-btn
              :loading="isSaving"
              class="mb-5 mt-7"
              @click="formSubmit"
              block
              dark
              >Create Account</v-btn
            >
          </v-form>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>
<script>
import userInvites from "../../../api/user-invites";
import Forms from "../../../mixins/Forms";
import { mapActions } from "vuex";

export default {
  name: "create-account",
  mixins: [Forms],
  metaInfo: {
    title: "Create Account",
  },
  data() {
    return {
      name: "",
      password: "",
      passwordConfirmation: "",
      show: false,
      isLoading: true,
      isSaving: false,
      nameRules: [(v) => !!v || "Name is required"],
      passwordRules: [(v) => !!v || "Password is required"],
      passwordConfirmationRules: [
        (v) => !!v || "Password confirmation is required",
      ],
      invite: null,
    };
  },
  created() {
    this.loadInvite();
  },
  computed: {
    inviteCode() {
      return this.$route.query.inviteCode;
    },
  },
  methods: {
    ...mapActions("auth", {
      attemptLogin: "attemptLogin",
    }),

    loadInvite() {
      let vm = this;
      vm.isLoading = true;
      userInvites
        .getInviteByCode(vm.inviteCode)
        .then((r) => {
          vm.invite = r.data;
          vm.name = vm.invite.name;
          vm.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          vm.$toast.error("Invite not found");
          vm.$router.push("/login");
        });
    },

    formSubmit() {
      let vm = this;

      let isValid = this.$refs["form"].validate();
      if (!isValid) {
        return false;
      }

      userInvites
        .acceptInvite(this.inviteCode, {
          name: this.name,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
        })
        .then(() => {
          vm.$toast.success("Account created successfully");
          vm.attemptLogin({
            email: this.invite.email,
            password: this.password,
          });
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          let errors = vm.getErrorsAsArray(e);
          if (errors.length) {
            vm.$toast.error(_.first(errors));
          }
        });

      vm.isSaving = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.logo-wrapper {
  text-align: center;
  margin-bottom: 30px;
}

.page-wrap {
  background-color: #f1f1f1 !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
